import http from '@/api/http'
import p from '@/common/privilege'

export default {
  getInfo () {
    const { VUE_APP_CURRENT_MODE } = process.env
    // console.log(process.env.VUE_APP_CURRENT_MODE);
    /** 海外模式 */
    const modelObj = {
      overseas: '/api/auth/user'
    }
    return http.get(modelObj[VUE_APP_CURRENT_MODE] ?? '/gcm/auth/user')
  },
  logout () {
    return http.get(p.OAuthExitUrl)
  }
}
