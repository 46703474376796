import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import echarts from 'echarts'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Vue from 'vue'
import Moment from 'vue-moment'
import App from './App.vue'
import router from './router'
import store from './store'
import './store/permission'
import './common/permission'

library.add(fas, fab)
dom.watch()
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.config.devtools = true
Vue.use(ElementUI)
Vue.use(Moment)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
