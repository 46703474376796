import api from '@/api'
import util from '@/common/utils'
import store from '@/store/store'
// 此项目暂时展示 新不良人 13
const INIT_APPID = 13
const app = {
  state: {
    device: 'desktop',
    sidebar: {
      opened: store.getSidebarStatus() === 'open',
      withoutAnimation: false
    },
    dataPickerValue: util.getDateRangeValue(-6),
    routerPrivilege: new Map(),
    channelCascadeIds: [[0]],
    channelCascadeOptions: [],
    servers: [],
    appId: undefined,
    channelId: 0,
    serverId: 0,
    gamesList: [],
    games: [],
    channels: []
  },
  getters: {
    device: state => state.device,
    sidebar: state => state.sidebar,
    isMobile: state => { return state.device === 'mobile' },
    routerPrivilege: state => state.routerPrivilege,
    channelId: state => state.channelId,
    appId: state => state.appId ?? sessionStorage.getItem('appId') ?? INIT_APPID
  },
  mutations: {
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    TOGGLE_SIDEBAR: state => {
      state.sidebar.opened = !state.sidebar.opened
      state.sidebar.withoutAnimation = false
      if (state.sidebar.opened) {
        store.setSidebarStatus('open')
      } else {
        store.setSidebarStatus('close')
      }
    },
    SET_GAMES: (state, games) => {
      // TODO: 目前只显示 13 不良人 15 万仙劫 的选择
      // 有配置显示id列表 则过滤
      const gameIdOptions = process.env.VUE_APP_GAME_ID_OPTIONS
      const filterGames = (gameIdOptions ?? '').split(',')
      const gamesList = games.filter((item) => {
        return gameIdOptions ? filterGames.includes(`${item.value}`) : true
      })
      state.gamesList = games
      state.games = gamesList
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      store.setSidebarStatus('close')
      state.sidebar.opened = false
      state.sidebar.withoutAnimation = withoutAnimation
    },
    SET_DATA_PICKER_VALUE: (state, dataPickerValue) => {
      state.dataPickerValue = dataPickerValue
    },
    SET_PRODUCTS: (state, products) => {
      state.products = products
    },
    SET_APP_ID: (state, appId) => {
      sessionStorage.setItem('appId', appId)
      state.appId = appId
    },
    SET_CHANNELS: (state, channels) => {
      state.channels = channels
    },
    SET_CHANNEL_CASCADE_OPTIONS: (state, channelCascadeOptions) => {
      state.channelCascadeOptions = channelCascadeOptions
      state.channelCascadeIds = [[0]]
    },
    SET_SERVERS: (state, servers) => {
      state.servers = servers
      state.serverId = 0
    },
    SET_CHANNEL_ID: (state, channelId) => {
      state.channelId = channelId
    },
    SET_SERVER_ID: (state, serverId) => {
      state.serverId = serverId
    },
    SET_CHANNEL_CASCADE_IDS: (state, channelCascadeIds) => {
      state.channelCascadeIds = channelCascadeIds
    },
    SET_ROUTER_PRIVILEGE: (state, routerPrivilege) => {
      state.routerPrivilege = routerPrivilege
    }
  },
  actions: {
    ToggleDevice: ({ commit }, device) => {
      commit('TOGGLE_DEVICE', device)
    },
    ToggleSideBar: ({ commit }) => {
      commit('TOGGLE_SIDEBAR')
    },
    CloseSideBar: ({ commit }, { withoutAnimation }) => {
      commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    ChangeDataPickerValue ({ commit, state }, dataPickerValue) {
      return new Promise((resolve, reject) => {
        commit('SET_DATA_PICKER_VALUE', dataPickerValue)
        resolve()
      })
    },
    GetGames ({ commit, state, getters }) {
      return new Promise((resolve, reject) => {
        api.gameList().then(res => {
          const games = []
          for (const d of res) {
            games.push({ value: d.id, label: d.id + '-' + d.name })
          }
          commit('SET_GAMES', games)
          if (games.length > 0) {
            let appId = store.getAppId()
            if (!appId) {
              // TODO: 默认新不良人 13
              appId = INIT_APPID
              // appId = games[games.length - 1].value
            }
            appId = parseInt(appId)
            commit('SET_APP_ID', appId)
            store.setAppId(appId)
          }
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ChangeGame ({ commit, state }, appId) {
      return new Promise((resolve, reject) => {
        // 渠道列表
        api.channelList(appId).then(res => {
          const zero = { value: 0, label: '0-全部' }
          const channels = [zero]
          const options = [zero]
          for (const p of res) {
            const obj = { value: p.id, label: p.id + '-' + p.name, children: [] }
            if (p.channels.length !== 0) {
              for (const c of p.channels) {
                const item = { ...c, value: c.id, label: c.id + '-' + c.name }
                obj.children.push(item)
                channels.push(item)
              }
            }
            options.push(obj)
          }
          commit('SET_APP_ID', appId)
          commit('SET_CHANNELS', channels)
          commit('SET_CHANNEL_CASCADE_OPTIONS', options)
          store.setAppId(appId)
        })
        api.serverList(appId).then(res => {
          const servers = [{ value: 0, label: '0-全部' }]
          if (res) {
            for (const d of res.data) {
              servers.push({ value: d.id, label: d.id + '-' + d.name })
            }
          }
          commit('SET_SERVERS', servers)
        })
        // 商品列表
        api.gameProducts(appId).then(res => {
          const products = []
          if (res) {
            for (const p of res) {
              products.push({ value: p.id, label: p.name, price: p.price })
            }
          }
          commit('SET_PRODUCTS', products)
        })
        // api.getChannelList(appId).then(res => {
        //   console.log(res)
        //   let channels = { 0: '0-全部' }
        //   for (let c of res) {
        //     channels[c.channelId] = c.channelId + '-' + c.channelName
        //   }
        //   commit('SET_APP_ID', appId)
        //   commit('SET_CHANNELS', channels)
        //   store.setAppId(appId)
        //   // api.getChannelGroup(appId).then(res => {
        //   //   console.log(res)
        //   // })
        // })
        // api.serverList(appId).then(res => {
        //   let servers = [{ value: 0, label: '0-全部' }]
        //   for (let d of res.data) {
        //     servers.push({ value: d.id, label: d.id + '-' + d.name })
        //   }
        //   commit('SET_SERVERS', servers)
        // })
        resolve()
      })
    },
    ChangeChannelId ({ commit, state }, channelId) {
      return new Promise((resolve, reject) => {
        commit('SET_CHANNEL_ID', channelId)
        resolve()
      })
    },
    // 服务器ID
    ChangeServerId ({ commit, state }, serverId) {
      return new Promise((resolve, reject) => {
        commit('SET_SERVER_ID', serverId)
        resolve()
      })
    },
    ChangeChannelCascadeIds ({ commit, state }, channelCascadeIds) {
      return new Promise((resolve, reject) => {
        commit('SET_CHANNEL_CASCADE_IDS', channelCascadeIds)
        resolve()
      })
    },
    DisposeRouterPrivilege ({ commit, state }, routerPrivilege) {
      return new Promise((resolve, reject) => {
        commit('SET_ROUTER_PRIVILEGE', routerPrivilege)
        resolve()
      })
    }
  }
}

export default app
