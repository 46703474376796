import api from '@/api/login'
import store from '@/store/store'

const user = {
  state: {
    token: store.getToken(),
    user: store.getUser()
  },
  getters: {
    token: state => state.token,
    user: state => state.user,
    resValues: state => state.user.resValues
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER: (state, user) => {
      state.user = user
    }
  },
  actions: {
    // 第三方验证登录
    ThirdLogin ({ commit, state }, accessToken) {
      return new Promise((resolve, reject) => {
        store.setToken(accessToken)
        commit('SET_TOKEN', accessToken)
        resolve()
      })
    },
    // 获取用户信息
    GetInfo ({ commit, state }) {
      return new Promise((resolve, reject) => {
        api.getInfo().then(data => {
          commit('SET_USER', data)
          store.setUser(data)
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 前端 登出
    FedLogOut ({ commit }) {
      return new Promise(resolve => {
        store.removeToken()
        store.removeUser()
        resolve()
      })
    }
  }
}

export default user
