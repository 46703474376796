import p from '@/common/privilege'
import Layout from '@/components/Layout'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 在当前页面时候再次点击当前页面，会有警告！
// 原因：在路由中添加了相同的路由。
// 解决：重写路由的push方法
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}

function load (view) {
  return () => import(`@/views/${view}.vue`)
}

const { VUE_APP_CURRENT_MODE } = process.env
/** 是否海外 */
const isOverseas = VUE_APP_CURRENT_MODE === 'overseas'

const routes = [
  { path: '/login', component: load('Login'), hidden: true },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    hidden: true,
    children: [{ path: '/home', name: '首页', component: load('Home'), icon: 'home' }]
  },
  {
    path: '/gcm',
    component: Layout,
    name: '聊天监控',
    icon: 'fa-robot',
    subMenu: true,
    hidden: isOverseas,
    meta: { breadcrumbLinkDisable: true, privileges: [p.P_KEFU, p.P_C_BANMA, p.P_YY, p.P_YX] },
    children: [
      { path: '/gcm/chatLog', name: '聊天记录', meta: { privileges: [p.P_KEFU, p.P_C_BANMA, p.P_YY, p.P_YX] }, component: load('gcm/ChatLog'), icon: 'fa-comment' },
      { path: '/gcm/monitorWord', name: '监控词', meta: { privileges: [p.P_KEFU, p.P_YY, p.P_YX] }, component: load('gcm/MonitorWord'), icon: 'fa-computer' },
      { path: '/gcm/autoBanRules', name: '封禁规则', meta: { privileges: [p.P_KEFU, p.P_YY, p.P_YX] }, component: load('gcm/BanRules/index'), icon: 'fa-hand-middle-finger' },
      /* 语音封禁 */
      { path: '/gcm/voiceRecording', name: '语音-记录', meta: { privileges: [p.P_KEFU, p.P_YY, p.P_YX] }, component: load('gcm/voiceRecording'), icon: 'fa-volume-up' },
      { path: '/gcm/voiceBanRule', name: '语音-封禁规则', meta: { privileges: [p.P_KEFU, p.P_YY, p.P_YX] }, component: load('gcm/voiceBanRule/index'), icon: 'fa-volume-up' },
      { path: '/gcm/voiceMonitoringKeyword', name: '语音-监控词', meta: { privileges: [p.P_KEFU, p.P_C_BANMA, p.P_YY, p.P_YX] }, component: load('gcm/voiceMonitoringKeyword'), icon: 'fa-volume-up' },
      { path: '/gcm/banLog', name: '封禁日志', meta: { privileges: [p.P_KEFU, p.P_C_BANMA, p.P_YY, p.P_YX] }, component: load('gcm/BanLog'), icon: 'fa-ban' },
      { path: '/gcm/untieLog', name: '解封日志', meta: { privileges: [p.P_KEFU, p.P_C_BANMA, p.P_YY, p.P_YX] }, component: load('gcm/UntieLog'), icon: 'fa-trash-can' },
      { path: '/gcm/ruleGroup', name: '规则组配置', meta: { privileges: [p.P_KEFU, p.P_YY, p.P_YX] }, component: load('gcm/RuleGroup'), icon: 'fa-layer-group' }
    ]
  },
  {
    path: '/wechat-code',
    component: Layout,
    name: '微信口令礼包码',
    icon: 'fa-cube',
    subMenu: true,
    hidden: isOverseas,
    meta: { breadcrumbLinkDisable: true, privileges: [p.P_YY, p.P_YX] },
    children: [
      { path: '/wechat-code/code-list', name: '口令列表', component: load('WechatCode/CodeList'), meta: { privileges: [p.P_YY, p.P_YX] }, icon: 'fa-brands fa-codepen' },
      { path: '/wechat-code/code-user-list', name: '用户兑换码列表', meta: { privileges: [p.P_YY, p.P_YX] }, component: load('WechatCode/CodeUserList'), icon: 'fa-receipt' }
    ]
  },
  {
    path: '/fee',
    component: Layout,
    name: '计费账单',
    subMenu: true,
    meta: { breadcrumbLinkDisable: true, privileges: [p.P_KEFU, p.P_YY, p.P_YX] },
    icon: 'file-inVoice-dollar',
    children: [
      { path: '/fee/order', name: '订单查询', component: load('Fee/Order/index'), icon: 'search-dollar' },
      {
        path: '/fee/channel-bill',
        name: '渠道账单查询',
        hidden: !isOverseas,
        // meta: { privileges: [p.P_YY] },
        component: load('ChannelBill/index'),
        icon: 'fa-layer-group'
      }
    ]
  },
  {
    path: '/search',
    component: Layout,
    name: '玩家查询',
    subMenu: true,
    meta: { breadcrumbLinkDisable: true, privileges: [p.P_KEFU, p.P_YY, p.P_YX] },
    icon: 'search-location',
    children: [
      { path: '/search/whale', name: '鲸鱼用户', component: load('Search/Whale'), icon: 'fish' },
      { path: '/search/user', name: '用户查询', component: load('Search/User/index'), icon: 'user-lock' },
      { path: '/search/ban', name: '封禁查询', hidden: isOverseas, component: load('Search/Ban/index'), icon: 'fa-ban' },
      { path: '/search/role', name: '角色查询', component: load('Search/Role'), icon: 'gamepad' }
    ]
  },
  {
    path: '/xshop',
    component: Layout,
    name: '官网充值',
    subMenu: true,
    hidden: isOverseas,
    meta: { breadcrumbLinkDisable: true, privileges: [p.P_KEFU, p.P_YY, p.P_YX] },
    icon: 'search-location',
    children: [
      { path: '/xshop/order', name: '官网充值', component: load('Xshop/Order'), icon: 'fish' },
      { path: '/xshop/kefu-code', name: '客服邀请码列表', component: load('Xshop/KefuCode/index'), icon: 'user-astronaut' },
      { path: '/xshop/DataBoard', name: '官网充值看板', meta: { privileges: [p.P_KEFU, p.P_YY] }, component: load('Xshop/DataBoard/index'), icon: 'signal' }
    ]
  },
  {
    path: '/apple',
    component: Layout,
    name: 'Apple记录',
    subMenu: true,
    hidden: isOverseas,
    meta: { privileges: [p.P_KEFU, p.P_YY, p.P_YX] },
    // meta: { breadcrumbLinkDisable: true },
    icon: 'fa-apple-whole',
    // component: load('Apple/Search')
    children: [
      { path: '/apple/search', name: '退款查询', meta: { privileges: [p.P_KEFU, p.P_YY, p.P_YX] }, component: load('Apple/Search/index'), icon: 'search-location' }
    ]
  },
  {
    path: '/gift-mall',
    component: Layout,
    name: '礼包商城',
    subMenu: true,
    hidden: isOverseas,
    icon: 'fa-gift',
    meta: { breadcrumbLinkDisable: true, privileges: [p.P_YY] },
    children: [
      { path: '/gift-mall/limited-time-gift', name: '限时礼包', meta: { privileges: [p.P_YY] }, component: load('GiftMall/LimitedTimeGift/index') },
      { path: '/gift-mall/periphery-gift', name: '周边好礼', meta: { privileges: [p.P_YY] }, component: load('GiftMall/PeripheryGift/index') },
      { path: '/gift-mall/gift-order', name: '订单查询', meta: { privileges: [p.P_YY] }, component: load('GiftMall/Order/index') },
      { path: '/gift-mall/welfare-gift', name: '福利礼包', meta: { privileges: [p.P_YY] }, component: load('GiftMall/WelfareGift/index') },
      { path: '/gift-mall/sign-in-gift', name: '签到礼包', meta: { privileges: [p.P_YY] }, component: load('GiftMall/SignInGift/index') },
      { path: '/gift-mall/email-template', name: '邮件模板', meta: { privileges: [p.P_YY] }, component: load('GiftMall/EmailTemplate/index') }
    ]
  },
  {
    path: '/integral-mall',
    component: Layout,
    name: '积分商城',
    subMenu: true,
    hidden: isOverseas,
    icon: 'fa-gifts',
    meta: { breadcrumbLinkDisable: true, privileges: [p.P_YY] },
    children: [
      { path: '/integral-mall/integral-gift', name: '积分礼包', meta: { privileges: [p.P_YY] }, component: load('IntegralMall/IntegralGift/index') },
      { path: '/integral-mall/integral-settings', name: '积分设置', meta: { privileges: [p.P_YY] }, component: load('IntegralMall/IntegralSettings/index') }
    ]
  },
  {
    path: '/internal-account',
    component: Layout,
    name: '',
    // icon: 'fa-gift',
    // component: load('InternalAccount/index')
    hidden: isOverseas,
    meta: { breadcrumbLinkDisable: true, privileges: [p.P_YY] },
    children: [
      { path: '/internal-account', name: '内部账号', meta: { privileges: [p.P_YY] }, component: load('InternalAccount/index'), icon: 'users-rectangle' }
    ]
  },
  {
    path: '/useroperations',
    component: Layout,
    name: '用户运营',
    subMenu: true,
    meta: { breadcrumbLinkDisable: true, privileges: [p.P_YY] },
    icon: 'fa-user',
    children: [
      { path: '/user-perations/user-recall', name: '流失用户', meta: { privileges: [p.P_YY] }, component: load('UserOperations/UserRecall/index'), icon: 'fa-eyedropper' },
      { path: '/user-perations/user-record', name: '召回记录', meta: { privileges: [p.P_YY] }, component: load('UserOperations/UserRecord/index'), icon: 'fa-eyedropper' },
      { path: '/user-perations/user-hotpay', name: '高热付费', meta: { privileges: [p.P_YY] }, component: load('UserOperations/Userhotpay/index'), icon: 'fa-eyedropper' },
      { path: '/user-perations/user-Layering', name: '用户分层', meta: { privileges: [p.P_YY] }, component: load('UserOperations/UserLayering/index'), icon: 'fa-eyedropper' },
      { path: '/user-perations/historicaluser-layering', name: '历史用户分层', meta: { privileges: [p.P_YY] }, component: load('UserOperations/HistoricalUserLayering/index'), icon: 'fa-eyedropper' },
      { path: '/user-perations/vip-userlayering', name: 'VIP用户分布', meta: { privileges: [p.P_YY] }, component: load('UserOperations/VipUserLayering/index'), icon: 'fa-eyedropper' }
    ]
  },
  // {
  //   path: '/assemblePage',
  //   component: Layout,
  //   name: '春节集结',
  //   subMenu: true,
  //   meta: { breadcrumbLinkDisable: true, privileges: [p.P_YY, p.P_YX] },
  //   icon: 'fa-gift',
  //   children: [
  //     { path: '/assemble-page/assemblh-page', name: '集结活动', meta: { privileges: [p.P_YY, p.P_YX] }, component: load('AssemblePage/AssemblhPage/index'), icon: 'fa-eyedropper' },
  //     { path: '/assemble-page/assembly-page', name: '活动奖励记录', meta: { privileges: [p.P_YY, p.P_YX] }, component: load('AssemblePage/AssemblyPage/index'), icon: 'fa-eyedropper' },
  //     { path: '/assemble-page/nine-grid-activity', name: '奖池奖励状况', meta: { privileges: [p.P_YY, p.P_YX] }, component: load('AssemblePage/NineGridActivity/index'), icon: 'fa-eyedropper' }
  //   ]
  // },
  {
    path: '/douyin-code',
    component: Layout,
    name: '抖音兑换',
    subMenu: true,
    hidden: isOverseas,
    icon: 'fa-puzzle-piece',
    meta: { breadcrumbLinkDisable: true, privileges: [p.P_YY] },
    children: [
      { path: '/douyin-code/game', name: '游戏', meta: { privileges: [p.P_YY] }, component: load('DouyinCode/Game') },
      { path: '/douyin-code/coupon', name: '兑换码列表', meta: { privileges: [p.P_YY] }, component: load('DouyinCode/Coupon') },
      { path: '/douyin-code/material', name: '素材库', meta: { privileges: [p.P_YY] }, component: load('DouyinCode/Material') },
      { path: '/douyin-code/order', name: '订单记录', meta: { privileges: [p.P_YY] }, component: load('DouyinCode/Order') },
      { path: '/douyin-code/product', name: '商品列表', meta: { privileges: [p.P_YY] }, component: load('DouyinCode/Product') },
      { path: '/douyin-code/shop', name: '商店列表', meta: { privileges: [p.P_YY] }, component: load('DouyinCode/Shop') }
    ]
  },
  {
    path: '/voucher',
    component: Layout,
    name: '代金券',
    subMenu: true,
    hidden: isOverseas,
    icon: 'fa-ticket',
    meta: { breadcrumbLinkDisable: true, privileges: [p.P_YY] },
    children: [
      { path: '/voucher/list', name: '代金券列表', meta: { privileges: [p.P_YY] }, component: load('Voucher/List/index') },
      { path: '/voucher/crowd-list', name: '发放人群', meta: { privileges: [p.P_YY] }, component: load('Voucher/Crowd/index') },
      { path: '/wechat-code/search-list', name: '使用查询', meta: { privileges: [p.P_YY] }, component: load('Voucher/SearchList/index') }
    ]
  },
  {
    path: '/mini-games',
    component: Layout,
    name: '小游戏',
    subMenu: true,
    hidden: isOverseas,
    icon: 'fa-gamepad',
    meta: { breadcrumbLinkDisable: true, privileges: [p.P_YY] },
    children: [
      { path: '/mini-games-code/mini-games', name: '联系客服配置', meta: { privileges: [p.P_YY] }, component: load('MiniGames/MiniGamesConfig/index'), icon: 'fa-cogs' },
      { path: '/mini-games-code/game-share', name: '游戏分享配置', meta: { privileges: [p.P_YY] }, component: load('MiniGames/GameShareConfig/index'), icon: 'fa-cogs' },
      { path: '/mini-games-code/payment-method', name: '支付方式配置', meta: { privileges: [p.P_YY] }, component: load('MiniGames/PaymentMethodConfig/index'), icon: 'fa-cogs' },
      { path: '/mini-games-code/login-popup', name: '登录弹窗配置', meta: { privileges: [p.P_YY] }, component: load('MiniGames/LoginPopupConfig/index'), icon: 'fa-cogs' }
    ]
  },
  {
    path: '/small_game_transfer',
    component: Layout,
    name: '小游戏转端',
    subMenu: true,
    hidden: isOverseas,
    icon: 'fa-gamepad',
    meta: { breadcrumbLinkDisable: true, privileges: [p.P_YY] },
    children: [
      { path: '/small_game_transfer/trans_endconfig', name: '转端信息配置', meta: { privileges: [p.P_YY] }, component: load('smallGameTransfer/transEndConfig'), icon: 'fa-cogs' },
      { path: '/small_game_transfer/trans_enddetail', name: '转端详情', meta: { privileges: [p.P_YY] }, component: load('smallGameTransfer/transEndDetail'), icon: 'fa-cogs' }
    ]
  },
  {
    path: '/wechat',
    component: Layout,
    name: '公众号',
    subMenu: true,
    hidden: isOverseas,
    icon: 'fa-gears',
    meta: { breadcrumbLinkDisable: true, privileges: [p.P_YY] },
    children: [
      { path: '/wechat/wx-menu', name: '微信公众号菜单', meta: { privileges: [p.P_YY] }, component: load('wechat/wx-menu'), icon: 'gears' }
    ]
  },
  // {
  //   path: '/blrcommunity',
  //   component: Layout,
  //   name: '发现配置',
  //   subMenu: true,
  //   icon: 'fa-home-lg',
  //   meta: { breadcrumbLinkDisable: true, privileges: [p.P_YY] },
  //   children: [
  //     {
  //       path: '/blr-community/tag-config',
  //       name: '标签栏配置',
  //       component: load('Blrcommunity/TagConfig/TagConfig'),
  //       icon: 'gears'
  //     },
  //     {
  //       path: '/blr-community/carousel-config',
  //       name: '轮播管理',
  //       component: load('Blrcommunity/CarouselConfig/CarouselConfig'),
  //       icon: 'gears'
  //     },
  //     {
  //       path: '/blr-community/topic-table',
  //       name: '主题管理',
  //       component: load('Blrcommunity/ToPicTable/ToPicTable'),
  //       icon: 'gears'
  //     },
  //     {
  //       path: '/blr-community/advisory-config',
  //       name: '资讯管理',
  //       component: load('Blrcommunity/AdvisoryConfig/AdvisoryConfig'),
  //       icon: 'gears'
  //     },
  //     {
  //       path: '/blr-community/publisher-config',
  //       name: '发布人管理',
  //       component: load('Blrcommunity/PublisherConfig/PublisherConfig'),
  //       icon: 'gears'
  //     },
  //     {
  //       path: '/blr-community/floating-config',
  //       name: '浮窗管理',
  //       component: load('Blrcommunity/FloatingConfig/FloatingConfig'),
  //       icon: 'gears'
  //     }
  //   ]
  // },
  {
    path: '/empty',
    component: Layout,
    redirect: '/empty',
    hidden: true,
    children: [{ path: '/empty', name: 'Empty', component: load('Empty'), icon: 'home' }]
  },
  // Always leave this last one
  { path: '*', component: load('404'), hidden: true }
]

const router = new VueRouter({
  routes
})

export default router
